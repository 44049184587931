@import "mixins";
@import "variables";

/** -----------SELECT------------
* Ширины по умолчанию нет
* Высота:
* Маленький (32px) => class="_small"
* Большой (38px) => по умолчанию
* read only => class="_read-only"
*/

.mat-mdc-select {
  background-color: $color-light-grey-40;
  border: none;
  border-radius: 4px;
  box-sizing: border-box;

  &._hide {
    /*TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.
*/
    .mat-mdc-select-value-text {
      opacity: 0;
    }
  }

  &._left {
    /*TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.
*/
    .mat-mdc-select-value-text {
      text-align: left;
    }
  }

  &._mid {
    height: 36px;
    /*TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.
*/
    .mat-mdc-select-value-text {
      line-height: 36px;
    }
  }

  &._thin {
    height: 32px;

    .mat-mdc-select-trigger {
      height: 32px;
    }
    /*TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.
*/
    .mat-mdc-select-value-text {
      line-height: 32px;
    }
  }

  &:hover,
  &:focus {
    border-color: $color-blue;
  }

  .mat-mdc-select-trigger {
    height: 36px;
    padding: 0 12px;
  }

  &._small .mat-mdc-select-trigger {
    height: 30px;
    padding: 0 16px;
  }
  &._medium .mat-mdc-select-trigger {
    height: 32px;
    padding: 0 12px;
  }
  &._very-small .mat-mdc-select-trigger {
    height: 24px;
    padding: 0 14px;
    .select-value {
      vertical-align: inherit;
      padding: 4px;
    }
  }
  /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.
*/
  &._small ~ .mat-form-field-label-wrapper label.mat-form-field-label.mat-empty {
    top: 21px;
  }
  /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.
*/
  &._small ~ .mat-form-field-label-wrapper label.mat-form-field-label {
    top: 24px;
  }

  &._weight {
    width: 60px;
    min-width: 60px;
    height: 28px;

    &[disabled] {
      width: 50px;
      min-width: 50px;
    }
    /*TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.
*/
    .mat-select-value-text {
      color: $color-grey-68;;
    }
    .mat-mdc-select-trigger {
      height: 28px;
      padding: 0 8px;
    ;
    }
  }
  /*TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.
*/
  .mat-select-value {
    vertical-align: middle;
  }

  .mat-mdc-select-arrow {
    color: $color-grey-100;
  }
  /*TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.
*/
  &._read-only .mat-select-arrow {
    opacity: 0;
  }
  /*TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.
*/
  .mat-select-placeholder {
    font-size: 14px;
    color: $color-light-txt;
  }
  /*TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.
*/
  .mat-mdc-select-value-text {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.15px;
    color: $color-txt;
  }


  &.mat-form-field-invalid,
  &.mat-focused.mat-form-field-invalid {
    .mat-mdc-input-element {
      border-color: $color-error;
    }
    /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.
*/
    .mat-form-field-label,
    .mat-datepicker-toggle-default-icon,
    .mat-form-field-label .mat-form-field-required-marker {
      color: $color-error;
    }
  }
}

/*TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.
*/
/*TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.
*/
.mat-select-disabled {
  border: 1px solid $color-input-disable;
  /*TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.
*/
  .mat-select-placeholder,
  .mat-select-value-text {
    color: $color-border;
  }

  &:hover,
  &:focus {
    border-color: $color-input-disable;
  }
  /*TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.
*/
  &._read-only .mat-select-placeholder {
    color: $color-light-txt;
  }
  /*TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.
*/
  &._read-only .mat-select-value-text {
    color: $color-txt;
  }
}

.mat-mdc-option {
  .mdc-list-item__primary-text {
    font-size: 14px;
      &::ng-deep .mat-select-search-input .mat-mdc-input-element {
          border:none;
      }
    }
  }

.phonebook-subdivision-option {
  &.mat-mdc-option {
    .mdc-list-item__primary-text {
      display: flex;
      align-items: center;
    }
  }
}

.mat-mdc-form-field-error {
  color: $color-error;
  padding-left: 12px;
}


.option-txt {
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  font-size: 14px;
  color: $color-main-text;
}

.option-logo {
  display: block;
  margin-right: 12px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  object-fit: cover;
}

.menu {
  &._long {
    width: 384px;
    //position: fixed;
    //top: 0;
    //bottom: 0;
    margin: auto;
    height: fit-content;
  }

  &._mid {
    width: 170px;
  }
  .menu__link {
    @include font-stack-main;
    display: flex;
    padding: 0 16px;
    box-sizing: border-box;
    width: 100%;
    align-items: center;
    min-height: 36px;
    color: $color-main-text;
    font-size: 14px;
    line-height: 20px;
    cursor: pointer;

    &._selected {
      background-color: $color-light-grey-40;
    }
  }
}

.kit._company {
  .mat-mdc-select {
    background-color: $color-light-grey-40;
  }
  /*TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.
*/
  .mat-select-value {
    color: $color-main-text;
  }
  /*TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.
*/
  /*TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.
*/
  .mat-select-disabled {
    background-color: $color-light-grey-40;
    /*TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.
*/
    .mat-select-arrow {
      color: $color-grey-60;
    }
    /*TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.
*/
    .mat-select-value-text {
      color: $color-grey-60;
    }
  }
}



.label {
  .label__row {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .button {
      width: 32px;
      height: 32px;
      margin-top: 8px;
      margin-left: 8px;
    }
  }
}

.option._flex .mdc-list-item__primary-text {
  display: flex;
  justify-content: space-between;
  width: 100%;

  .additional-txt {
    color: $color-grey-100 !important;
    letter-spacing: 0.15px;
  }
}

.checkmark-panel {
  min-width: 120px;


  .pseudo-checkmark {
    visibility: hidden;
    margin: 0 !important;
    color: $color-blue-default;
  }

  .mdc-list-item--selected .pseudo-checkmark {
    visibility: visible;
  }

  &._blue .pseudo-checkmark {
    color: $color-blue-default;
  }

  //.mat-mdc-option.mdc-list-item--selected {
  //  background: transparent !important;
  //}
}

._select24 {
  .mat-mdc-select-trigger {
    height: 24px;
    padding: 0 8px;
  }
}

.mat-mdc-select-panel .mat-mdc-option {
  &.custom {
    height: 36px;

    &:first-child {
      margin-top: 8px;
    }

    &.mat-mdc-selected:not(.mat-mdc-option-multiple) {
      background-color: $color-light-grey-24;
    }
  }
}


/*
.phonebook-subdivision-option {
  &.mat-option {
    .mat-option-text {
      display: flex !important;
    }
  }
}*/

.mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
  color: $color-main-text !important;
}

.mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled):not(.mat-mdc-option-multiple) {
  background-color: $color-light-grey-24 !important;
}

.select {
  &._no-arrow {
    .mat-mdc-select-arrow-wrapper {
      display: none;
    }
    -webkit-appearance: none;
  }
}

.mat-mdc-select-placeholder {
  font-size: 14px;
}