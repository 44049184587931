@import "mixins";
@import "variables";

.mat-mdc-icon-button {
  color: $color-input-title;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    & .mat-mdc-button-persistent-ripple {
      display: none;
    }
  }

  .mat-icon {
    line-height: 24px;
    font-size: 24px;
  }

  &._blue {
    color: $color-blue;
  }

  mat-mdc-icon-button
  //&:hover {
  //  color: $color-blue;
  //  opacity: 0.5;
  //}

  &._red {
    color: $color-error;
  }


}
