@import "variables";

.paginator {
  &._tasks {
    border: 1px solid $color-light-grey-68;
    border-radius: 4px;

    .mdc-notched-outline {
      display: none;
    }

    .mat-mdc-text-field-wrapper {
      padding: 0;
    }

    .mat-mdc-paginator-container {
      min-height: 40px;
    }

    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
    .mat-form-field-infix {
      border-top: none;
    }

    .mat-mdc-paginator-page-size {
      align-items: center;
    }
    /* TODO(mdc-migration): The following rule targets internal classes of paginator that may no longer apply for the MDC version. */
    .mat-paginator-page-size-select {
      width: 80px;
      margin-top: 0;
    }
  }
}
