
/* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
/* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
.mat-button-toggle-group {
  height: 36px;

  &._thin {
    height: 26px;
  }

  &._outlined {
    border: 0 !important;
  }

  &._blue {
    /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
    .mat-button-toggle-checked {
      background-color: $color-blue;
      color: $color-white!important;
    }
  }
}


/* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
.mat-button-toggle {
  &._outlined {
    border: 1px solid $color-light-grey-68;
    margin-left: -1px;

    &:first-child {
      border-bottom-left-radius: $grid;
      border-top-left-radius: $grid;

      margin-left: 0;
    }

    &:last-child {
      border-radius: 0 $grid $grid 0;
    }
  }
}

/* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
.mat-button-toggle-checked {
  &._outlined {
    border-color: $color-blue!important;
    z-index: 2;
  }
}

/* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
.mat-button-toggle-checked {
  background-color: $color-button-blue !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
.mat-button-toggle {
  outline: none;
}

/* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
/* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
.mat-button-toggle {
  font-size: 14px;

  &::ng-deep {
    /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
    .mat-button-toggle-label-content {
      line-height: 35px;
    }
  }
}

.header, .head, .rating {
  .mat-pseudo-checkbox {
    display: none !important;

  }
}


/* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
/* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
.mat-button-toggle._thin {
  /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
  .mat-button-toggle-label-content {
    padding: 0 6px;
  }
}

.mat-button-toggle._thin._no-padding {
  .mat-button-toggle-label-content {
    padding: 0;
  }
}

.mat-button-toggle-button {
  display: flex;
  align-items: center;
}