@import 'variables';

.mat-mdc-tooltip {
    .mdc-tooltip__surface {
        position: relative;
        box-sizing: border-box;
        box-shadow: 0px 4px 16px rgba(24, 27, 41, 0.24);
        border-radius: 4px !important;
        background-color: $color-txt;
        padding: 10px 14px !important;
        width: max-content;
        min-height: 24px;
        overflow: visible !important;
        color: $color-white !important;
        font-weight: 500;
        font-size: 12px !important;
        line-height: 16px;
        letter-spacing: 0.4px;
        //word-break: break-word;
        white-space: pre-line;
    }
}

.custom-tooltip-long {
    .mdc-tooltip__surface {
        display: block;
        word-break: break-word !important;
        text-wrap: normal !important;
        white-space: normal !important;
        width: auto !important;
        overflow: hidden !important;
        text-overflow: initial !important;
    }
}

.description-tooltip {
    .mdc-tooltip__surface {
        white-space: pre-line;
    }
}

.custom-tooltip {

    .mdc-tooltip__surface {
        background-color: $color-white !important;
        color: $color-dark-grey-text !important;
        font-weight: 400 !important;
        font-size: 12px !important;
        line-height: 16px !important;
    }
}

.academy-tooltip {
    .mdc-tooltip__surface {
        box-shadow: 0px 8px 16px -4px #4a586d29;
        border: 1px solid $color-light-grey;
        background-color: $color-white;
        color: $color-dark-grey-text !important;
        font-size: 12px !important;
        line-height: 16px;
        letter-spacing: 0.2px;
    }

}

.error-snackbar {
    .mdc-tooltip__surface {
        background-color: $color-error;
        color: white;
    }
}
