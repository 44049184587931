@import 'fonts';
@import 'typography';
@import "colors";

@mixin invisible-scroll {
	scrollbar-width: none; /* Firefox */
	-ms-overflow-style: none;  /* Internet Explorer 10+ */

	&::-webkit-scrollbar { /* WebKit */
		width: 0;
		height: 0;
	}
}

@mixin icon-color($color) {
	color: $color;

	&::ng-deep {
		path, rect {
			fill: $color;
		}
		circle {
			stroke: $color;
		}
	}
}

@mixin icon-color-without-fill($color) {
	color: $color;

	&::ng-deep {
		path, rect {
			stroke: $color;
		}
	}
}

@mixin icon-size($size) {
	&::ng-deep {
		.mat-icon {
			width: $size;
			height: $size;
			line-height: $size;
			font-size: $size;
		}
	}
}

@mixin mat-icon-size($size) {
	width: $size;
	height: $size;
	line-height: $size;
	font-size: $size;
}

@mixin font-stack($primary) {
	font-family: $primary, 'Helvetica Neue', Arial, sans-serif;
}

@mixin ellipsis {
	display: block;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

@mixin font-stack-main {
	@include font-stack('Roboto');
	letter-spacing: normal;
}

@mixin nl {
	list-style: none;
	padding: 0;
	margin: 0;
}

@mixin box {
	display: block;
	padding: 21px 16px;
	box-sizing: border-box;
	background-color: #fff;
	box-shadow:
		0 1px 1px 0 rgba(0,0,0,0.14),
		0 2px 1px -1px rgba(0,0,0,0.12),
		0 1px 3px 0 rgba(0,0,0,0.2);
		border-radius: 4px;
}

@mixin link {
	&,
	&:link,
	&:visited {
		color: $color-blue;
		text-decoration: none;
	}

	&:hover {
		cursor: pointer;
		color: $color-blue-hover;
	}
}

@mixin input{
	height: 40px;
	font-size: 14px;
	line-height: 16px;
	width: 100%;
	box-sizing: border-box;
	border: none;
	padding: 12px 12px 9px 12px;
	position: relative;
	z-index: 0;

	&:focus,
	&:active {
		outline: none;
	}
	&::placeholder {
		color: rgba(0, 0, 0, 0.26);
	}
}

@mixin flex-column-stretch {
	display: flex;
	flex-flow: column nowrap;
	flex: 1 1 auto;
}

@mixin filter() {
	margin-right: 20px;
	width: 200px;
}


@mixin sidebar-title {
	margin: 0;
	@include font-stack('Roboto');
	font-weight: normal;
	font-size: 20px;
	line-height: 24px;
	color: $color-txt;
}

@mixin sidebar-paddings {
	padding: 16px 24px 14px 16px;
}

@mixin scroll {
	& {
		scrollbar-width: thin;
	}

	&::-webkit-scrollbar {
		width: 5px;
		height: 5px;
	}

	&::-webkit-scrollbar-thumb:vertical {
		margin: 5px;
		background-color: $color-grey-40;
		-webkit-border-radius: 5px;
	}

	&::-webkit-scrollbar-thumb:horizontal {
		margin: 5px;
		background-color: $color-grey-40;
		-webkit-border-radius: 5px;
	}

	&::-webkit-scrollbar-button:start:decrement,
	&::-webkit-scrollbar-button:end:increment {
		height: 5px;
		display: block;
	}
}

@mixin scroll-padding {
	&::-webkit-scrollbar {
		width: 12px;
		height: 12px;
	}

	&::-webkit-scrollbar-thumb:vertical {
		margin: 5px;
		background-color: $color-grey-40;
		-webkit-border-radius: 5px;

		border-right: 4px solid white;
		border-left: 4px solid white;
	}

	&::-webkit-scrollbar-thumb:horizontal {
		margin: 5px;
		background-color: $color-grey-40;
		-webkit-border-radius: 5px;
		border-top: 4px solid white;
		border-bottom: 4px solid white;
	}

	&::-webkit-scrollbar-button:start:decrement,
	&::-webkit-scrollbar-button:end:increment {
		height: 5px;
		display: block;
	}
}

@mixin statusColors {
	&._blue {
		color: #2196F3;
	}

	&._grey {
		color: #B4B4B4;
	}

	&._green {
		color: #8BC34A;
	}

	&._red {
		color: #F1534C;
	}

	&._cyan {
		color: #00BCD4;
	}

	&._orange {
		color: #FFB800;
	}
}

@mixin statusTemplateColors {
	&._blue {
		color: #2196F3;
		background-color: $color-blue-light;
	}

	&._grey {
		color: #B4B4B4;
		background-color: $color-light-grey-68;
	}

	&._green {
		color: #8BC34A;
		background-color: $color-success-bg;
	}

	&._red {
		color: #F1534C;
		background-color: $color-error-bg;
	}

	&._cyan {
		color: #00BCD4;
	}

	&._orange {
		color: #FFB800;
		background-color: $color-orange-bg-tab;
	}
}

@mixin gallery {
	.back-layer {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 200;
		background-color: rgba(0, 0, 0, 0.5);
	}

	.fullscreen-img {
		display: block;
		max-width: 85vw;
		max-height: 90vh;
	}

	.close {
		position: fixed;
		top: 30px;
		right: 30px;
		color: $color-grey-bg;
		&:hover {
			opacity: 1;
		}
	}

	.arrow {
		position: fixed;
		top: 20vh;
		height: 60vh;
		width: 100px;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		user-select: none;
		.mat-icon {
			color: $color-grey-bg;
			transform: scale(1.9);
		}

		&:hover {
			cursor: pointer;
			.mat-icon {
			transform: scale(2.0);
			}
		}

		&:active {
			.mat-icon {
			transform: scale(2.0);
			}
		}

		&._left {
			left: 0;
		}

		&._right {
			right: 0;
		}
	}
}
