@import "mixins";
@import "variables";

.mat-mdc-dialog-container {
  border-radius: 10px;
}

.dialog {
  display: flex;
  flex-direction: column;
  @include font-stack-main;
  position: relative;
  height: 100%;
}

.dialog__header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 24px;
  height: 24px;
  align-items: center;
  cursor: default;
  &.__modal {
    height: 20px;
  }

  &._with-border {
    border-bottom: 1px solid $color-light-grey-100;
  }
}

.dialog__title {
  margin: 0;
  font-size: 18px;
  line-height: 24px;
  font-weight: 500;
  color: $color-main-text;
}

.dialog__footer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 15px 24px;
  margin-top: auto;
  background-color: $color-main-bg;
  border-top: 1px solid $color-light-grey-68;

  .button + .button {
    margin-left: 8px;
  }
}

.close {
  color: $color-grey-100;
  font-size: 22px;
  min-width: 24px;
  line-height: 24px;
  @include icon-color($color-grey-100);

  &:hover {
    opacity: 0.8;
    cursor: pointer;
  }
}
