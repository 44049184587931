@import "variables";
@import "mixins";
@import "icon-buttons";

.sidebar-back-outline {
  position: absolute;
  width: 100%;
  height: 100vh;
  top: 0;
  background-color: $color-input-title;
  z-index: 1000;
}

.sidebar-content {
  position: fixed;
  display: flex;
  flex-direction: row;
  height: 100vh;
  max-width: 900px;
  width: fit-content;
  top: 0;
  right: 0;
  box-sizing: border-box;
  @include font-stack-main;
  z-index: 1000;
}

.sidebar-card {
  position: relative;
  width: 440px;
  height: 100%;
  box-sizing: border-box;
  overflow: hidden;
  background-color: $color-white;
  border-radius: 8px;


  .section__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .section__title {
    display: flex;
    margin-bottom: 14px;
    margin-top: 12px;
    font-weight: 500;
    letter-spacing: 0.1px;
    color: $color-txt;
    font-size: 14px;
  }

  &._float-r {
    border-radius: 8px 0 0 8px;
  }
}

.sidebar-card__header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  //padding: 0 16px;
  gap: 8px;
  padding: 0 16px 0 24px;
  height: 44px;
  background-color: $color-white;
  border-bottom: 1px solid $color-light-grey-68;

  .button {
    .icon {
      @include icon-color($color-grey-100);
    }
  }
}

.sidebar-card__title {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: $color-grey-100;
  font-weight: 400;
  line-height: 20px;
}

.button._icon + .button._icon {
  margin-left: 8px;
}

.sidebar-card__toolbar {
  display: flex !important;
  flex-direction: row;
  align-items: center;
  gap: 8px;

  .button {
    margin-left: 0 !important;
  }
}

.sidebar-card__content {
  @include scroll;
  height: calc(100% - 44px);

  [data-overlayscrollbars-viewport] {
    display: flex;
    flex-direction: column;
  }
  overflow: auto;

  position: relative;
  z-index: 0;
}

.sidebar-card__section {
  display: block;
  padding: 16px 24px;
  box-sizing: border-box;

  & + & {
    border-top: 3px solid $color-light-grey-68;
  }
}

.sidebar-card__section-title {
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  letter-spacing: 0.1px;
  color: $color-txt;
  margin: 0 0 22px;

  .mat-expansion-panel-header & {
    margin-bottom: 0;
  }
}

.sidebar-card__section-subtitle {
  font-size: 12px;
  line-height: 16px;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 0.2px;
  color: $color-grey-68;
  margin: 0 0 14px;
}

.sidebar__tabs {
  display: flex;
  min-height: 58px;
  justify-content: center;
  padding: 12px 24px 0;
  box-sizing: border-box;
  background: linear-gradient(0deg, rgba(215, 223, 233, 0.24), rgba(215, 223, 233, 0.24)), #FFFFFF;
  overflow: hidden;
}

.sidebar__tab {
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: $box-shadow1;
  height: 46px;
  width: 190px;
  cursor: pointer;
  border-bottom: 3px solid transparent;
  box-sizing: border-box;
  background-color: transparent;

  & + & {
    margin-left: 8px;
  }

  .txt {
    color: $color-grey-100;
    font-size: 14px;
    font-weight: 500;
  }

  &._active {
    background-color: $color-white;
    border-radius: 4px 4px 0 0;
    border-bottom: 3px solid $color-blue-default;
    cursor: default;

    .txt {
      color: $color-main-text;
    }
  }
}
