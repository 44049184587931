@import "variables";
@import "mixins";

.mat-mdc-chip.mat-mdc-standard-chip {
  font-size: 14px;
  font-weight: 400;
  color: $color-main-text-grey;
  border: 1px solid $color-light-grey;
  background-color: $color-white;

  .txt {
    @include ellipsis;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of chips that may no longer apply for the MDC version. */
  /* TODO(mdc-migration): The following rule targets internal classes of chips that may no longer apply for the MDC version. */
  &.mat-chip-with-trailing-icon{
    background-color: $color-light-grey-40;
    border-color: transparent;
    /* TODO(mdc-migration): The following rule targets internal classes of chips that may no longer apply for the MDC version. */
    .mat-chip-ripple {
      display: none;
    }

    &:hover {
      cursor: default;
      background-color: $color-light-grey-40;
      border-color: transparent;
    }
  }

  &:hover {
    cursor: pointer;
    background-color: $color-white;

    &::after {
      display: none;
    }
  }

  &:focus {
    &:after {
      display: none;
    }
  }

  &._active {
    color: $color-blue;
    background-color: $color-blue-hover-light;
    border-color: transparent;
  }
}

.kit {
  .mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled) {
    background-color: $color-light-grey-40;
  }
}

.kit._company {
  input.mat-mdc-chip-input {
    display: block;
    min-width: 100%;
    margin: 13px;
    font-size: 14px;
    letter-spacing: normal;
  }

  .mdc-notched-outline {
    display: none;
  }

  .mat-mdc-form-field:not(.mat-form-field-disabled) {
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
    .mdc-text-field {
      margin-top: 8px;
      padding: 0;
      border-top: none;
      background-color: transparent;
      outline: none;
    }

    .mat-mdc-form-field-infix {
      margin-top: 0px;
      padding: 10px 12px;
      border-top: none;
      background-color: $color-light-grey-40;
    }
  }
  .mat-form-field-disabled {
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
    .mat-mdc-form-field-infix {
      margin-top: 0px;
      padding: 8px 0 8px;
      border-top: none;
    }
  }
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
  .mat-form-field-wrapper {
    padding-bottom: 0;
  }
  .mat-mdc-chip.mat-mdc-standard-chip {
    border-radius: 4px;
    color: $color-blue-default;
    background-color: $color-blue-hover-light;
    border-color: transparent;

    .mdc-evolution-chip__text-label {
      color: $color-blue-default;
    }
  }
  /* TODO(mdc-migration): The following rule targets internal classes of chips that may no longer apply for the MDC version. */
  .mat-mdc-chip.mat-mdc-standard-chip.mat-chip-disabled {
    opacity: 1;
    cursor: default;
  }
}

.grey-chips {
  .mat-mdc-chip.mat-mdc-standard-chip {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 6px 8px 6px 12px;
    gap: 8px;
    background: $color-light-grey-40;
    border-radius: 65px;
    border: none;
  }
}

.mat-mdc-standard-chip .mdc-evolution-chip__cell--primary,
.mat-mdc-standard-chip .mdc-evolution-chip__action--primary,
.mat-mdc-standard-chip .mat-mdc-chip-action-label {
  overflow: hidden !important;
}