@import "mixins";
@import "variables";

/** -----------BUTTON------------
* Ширина по размеру контента
* Высота 36px
* Все кнопки mat-flat-button + class="button"
* Цвета через классы: "_blue", "_green", "_orange"
* 5 кнопка => class="_upcase"
* Без рамки => class="transparent"
* Кнопка с иконкой => mat-icon-button + mat-icon
* Цвет у всех mat-icon-button по-умолчанию синий
*/

.mdc-button__label {
  display: flex;
  justify-content: center;
  align-items: center;
}

.mat-mdc-unelevated-button.button {
  height: 36px;
  line-height: 34px;
  border: none;
  letter-spacing: normal;
  font-size: 14px;
  font-weight: 400;
  @include font-stack-main;
  /*TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.
*/

  .mdc-button__label {
    white-space: nowrap;
  }

  &._new._add .mat-button-wrapper{
    display: flex;
    align-items: center;
  }

  &._green {
    color: $color-white;
    background-color: $color-green;
  }

  &._blue {
    color: $color-white;
    background-color: $color-blue;
  }

  &._orange {
    color: $color-white;
    background-color: $color-warn;
  }

  &._grey {
    border: 1px solid rgba(0, 0, 0, 0.2);
    background-color: $color-bg-gray;
    color: $color-txt;

    &._white-txt {
      color: $color-white;
    }
  }

  &._light-grey-40 {
    background-color: $color-light-grey-40;
    border: 1px solid $color-light-grey;
    color: $color-grey-100;
  }

  &._light-grey-100 {
    background-color: $color-light-grey !important;
  }

  &._white {
    color: $color-blue;
    background-color: $color-white;
    border: 1px solid #1985FF;
    &._main-txt {
      border: 1px solid $color-light-grey;
      color: $color-main-text;
    }
  }

  &._transparent {
    background-color: transparent;
    color: $color-blue;
  }

  &._cyan {
    background-color: #00BCD4;
    color: $color-white;
  }

  &._link {
    color: $color-blue;
    background-color: transparent;
    border: none;

    &._red {
      color: $color-error;
    }

    &._grey {
      color: $color-grey;
    }
  }

  &:hover {
    opacity: 0.7;
  }

  &[disabled], &[disabled]:hover {
    color: $color-white;
    opacity: 1;
    border: none;
    background-color: $color-border;
    cursor: default;
  }

  &.transparent {
    color: $color-blue;
    background-color: transparent;
    @include font-stack-main;

    &._upcase {
      outline: none;
      border: 1px solid transparent;
      text-transform: uppercase;
      &:hover {
        color: $color-blue;
        opacity: 1;
        border: 1px solid $color-button-border;
        border-radius: 2px;
        background-color: $color-button-bg-gray;
      }
    }

    &[disabled], &[disabled]:hover {
      color: $color-light-txt;
      background-color: transparent;
      opacity: 1;
    }

  }
  &.icon {
    padding: 0;
    width: 34px;
    min-width: 36px;
    display: flex;
    justify-content: center;

    &:before {
      content: '';
      display: block;
    }

    &._left:before {
      width: 12px;
      height: 8px;
      transform: rotate(270deg);
    }

    &._right:before {
      width: 12px;
      height: 8px;
      transform: rotate(90deg);
    }
  }
}

.add {
  display: flex;
  align-items: center;
  color: $color-blue;
  height: 40px;

  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }

  .mat-icon {
    font-size: 16px;
    line-height: 16px;
    width: 16px;
    height: 16px;
    margin-right: 10px;
  }
}

.kit {
  .button {
    &._icon {
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;
      width: 24px;
      height: 24px;
      background-color: transparent;
      outline: none;
      padding: 0;
      border-radius: 4px;

      &._chat {
        .mat-icon {
          width: 18px;
        }

        &._chat-open {
          background-color: $color-blue-hover-light;
          // color: $color-blue-default;
          .mat-icon {
            @include icon-color($color-blue-default);
          }
        }
      }

      &:hover {
        background-color: $color-light-grey-40;
        cursor: pointer;
      }

      &:active {
        background-color: $color-blue-hover-light;

        .mat-icon {
          color: $color-blue;
        }
      }

      &._active {
        background-color: rgba(238, 84, 82, 0.1);

        .mat-icon {
          color: $color-error;
        }
      }

      .mat-icon {
        color: $color-grey;
      }
    }
  }
}

.button {
  height: 36px;

  &.mat-mdc-icon-button {
    display: flex;
    align-items: center;
    line-height: 36px;
    width: 36px;
    height: 36px;
  }

  &._thin {
    height: 28px;
    line-height: 28px;
    padding: 0 8px;

    .mat-icon {
      line-height: 18px;
      height: 20px;
      width: 18px;
      font-size: 18px;
    }
  }

  &._toggle {
    position: relative;
    border: none;
    display: flex;
    cursor: pointer;
    align-items: center;
    color: $color-grey-100;
    background-color: $color-white;
    font-size: 14px;
    font-weight: 500;
    border-radius: 4px;

    &:disabled {
      color: $color-light-grey-68 !important;
    }

    &._thin {
      height: 28px;

      .icon {
        width: 15px;
        margin-right: 4px;
      }
    }

    &._thin24 {
      height: 24px;

      .icon {
        width: 18px;

       & + .txt {
         margin-left: 4px;
       }
      }
    }

    &._no-padding24 {
      min-width: 24px;
      padding: 0;
      justify-content: center;
    }

    &._filled {
      background-color: $color-light-grey-24;
    }

    @media screen and (max-width: 1200px) {
      .icon + .txt {
        display: none;
      }
    }

    &._border {
      border: 1px solid $color-light-grey-68;
    }

    &:focus {
      outline: none;
    }

    &._selected {
      &:after {
        content: '';
        display: block;
        position: absolute;
        top: -4px;
        right: -5px;
        width: 8px;
        height: 8px;
        border: 2px solid $color-white;
        border-radius: 50%;
        background-color: $color-error;
      }
    }

    &._hide-button {
      display: none;
    }

    &._opened,
    &._selected {
      background-color: $color-button-blue;
      color: $color-blue;

      .txt {
        color: $color-blue;
      }
      .icon {
        @include icon-color($color-blue);
      }
    }
  }
}


.button {
  &._add._user {
    line-height: 40px;
    height: 40px;
    width: 40px;
    background-color: $color-button-blue;
    color: $color-blue;
    .txt {
      position: absolute;
      left: 56px;
      font-weight: normal;
    }
    .mat-icon {
      font-size: 20px;
    }
  }
}


/*TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.
*/
.mat-button-toggle-button:disabled {
  opacity: 0.4;
}

.button._medium {
  .button {
    font-family: "Roboto", Arial, sans-serif;
    font-weight: 500;
  }

  font-family: "Roboto", Arial, sans-serif;
  font-weight: 500;

  .txt {
    font-family: "Roboto", Arial, sans-serif;
    font-weight: 500;
  }
}

.radius-button {
  border-radius: 4px;
  background-color: $color-white;
  padding: 8px 16px;
  display: flex;
  justify-content: center;
  align-content: center;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;  /* 142.857% */
  letter-spacing: 0.1px;
  color: $color-dark-grey-text;
  border: 1px solid $color-light-grey-68;
  box-sizing: border-box;
  cursor: pointer;

  &:disabled {
    cursor: default;
    color: $color-light-grey-100;
    border-color: $color-light-grey-100;
  }

  &:not(:disabled):hover {
    background-color: $color-light-grey-24-no-alpha;
  }

  &:not(:disabled):focus {
    outline: 2px solid $color-blue-default;
  }

  &:not(:disabled):active {
    background-color: $color-light-grey-40;
  }
}

.radius-button--primary {
  @extend .radius-button;
  color: $color-white;
  background-color: $color-blue-default;
  border-color: $color-blue-default;

  &:disabled {
    cursor: default;
    color: $color-grey-100;
    background-color: $color-light-grey-100;
    border-color: $color-light-grey-100;
  }

  &:not(:disabled):hover {
    background-color: $color-blue-hover;
  }

  &:not(:disabled):active {
    background-color: $color-blue-pressed;
  }
}

.radius-button--text {
  @extend .radius-button;
  background: transparent;
  border-color: transparent;

  &:disabled {
    cursor: default;
    color: $color-grey-100;
    background-color: transparent;
    border-color: transparent;
  }

  &:not(:disabled):hover {
    background-color: $color-light-grey-40;
  }

  &:not(:disabled):active {
    background-color: $color-light-grey-68;
  }
}

.button {
  .responsive {
    svg {
      width: 100%;
      height: 100%;
    }
  }
}

.txt {
  letter-spacing: normal !important;
}

/** тач-панелька вылазиет за пределы кнопки и делает скролы, сделаем ровно по кнопке */
.mat-mdc-icon-button .mat-mdc-button-touch-target {
  height: 100% !important;
  width: 100% !important;
  transform: unset !important;
  top: 0 !important;
  left: 0 !important;
}
