@import "mixins";
@import "variables";

.mat-mdc-menu-panel {
    @include scroll;
    min-height: 52px !important;
    max-width: 380px!important;
    box-shadow: $box-shadow3 !important;

    &._functions {
        max-width: 500px !important;
    }
}

.sort-tasks .mat-mdc-menu-content {
    padding-top: 0!important;
    padding-bottom: 0!important;
}
