@import "variables";
@import "mixins";

.radius-theme {
  .mat-mdc-tab-header {
    --mdc-secondary-navigation-tab-container-height: 45px;
  }

  .mdc-tab__text-label {
    @include font-stack-main;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: $color-grey-100;
    letter-spacing: normal;
    opacity: .7;
    position: relative;
    top: 1px;
  }
  .mdc-tab--active {
    .mdc-tab__text-label {
      color: $color-main-text !important;
      opacity: 1;
    }
  }

  /*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
  .mat-checkbox-ripple {
    opacity: 0;
  }
  /*TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
  .radio-group-button.mat-radio-disabled {
    opacity: 0.5;
  }

  .mat-mdc-checkbox {
    display: block;
  }

  .mat-internal-form-field>label {
    padding-left: 0px !important;
  }

  .mat-mdc-checkbox .mdc-form-field  {
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
    width: 100%;
    white-space: normal;
  }

  &._filters {
    .mat-mdc-tab-header {
      border-bottom: none;
    }

    .mat-mdctab-label-container {
      position: static;
    }

    .mat-mdc-tab-body-content:before {
      display: none;
    }
    .mat-mdc-tab-label-container {
      padding: 20px 16px 6px;
      box-sizing: border-box;
      border-bottom: 1px solid $color-light-grey-68;
    }

    .mat-mdc-tab-label-container {
      width: 438px;
      min-width: 0;
      padding: 0 24px;
      box-sizing: border-box;
      border-bottom: 1px solid $color-light-grey-68;
    }
  }

  .mat-mdc-tab-label-container {
    min-width: 564px;
    padding: 0 18px;
    margin: 0;
    z-index: 99;
    background-color: $color-white;
    color: $color-grey;
    box-sizing: border-box;
    border-bottom: 1px solid $color-light-grey-68;
  }

  .kit .input {
    &:not(._num) {
      background-color: transparent;
    }
  }
  /*TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
  .mat-tab-body-content:before {
    //display: block;
    //height: 48px;
    //content: '';
  }

  .mat-mdc-tab-nav-bar, .mat-mdc-tab-header {
    border-bottom: none;
  }
  /*TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
  .mat-tab-label-active {
    color: $color-txt;
    opacity: 1;
  }
  /*TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
  .mat-tab-label {
    margin: 0 16px;
    padding: 12px 0 !important;

    &:first-child {
      margin-left: 0;
    }
  }

  &._dashboard {
    .mat-mdc-tab-body-content {
      padding: 20px 0 90px;
      overflow-y: auto;
      height: 100%;
      @include scroll();
    }

    .mat-mdc-tab {
      margin: 0 16px;

      &:first-child {
        margin-right: 0;
      }
    }

    .mat-mdc-tab-label-container {
      padding: 0 4px;
    }
  }
  /*TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
  .mat-tab-body-content {
    overflow: hidden;
    padding: 0 6px;
    box-sizing: border-box;
    padding-top: 5px;
  }

  .radio-group {
    padding-bottom: 2px;
    margin-bottom: -1px;
    /*TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
    .mat-radio-label {
      display: contents;
    }
    /*TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
    .mdc-radio {
      position: absolute;
      right: 0;
    }
    /*TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
    .mat-radio-label-content {
      padding-left: 0;
      color: $color-dark-grey-text;
    }

    .radio-group-label {
      display: contents;
      margin-bottom: 12px;
      color: $color-main-text;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      letter-spacing: 0.1px;
    }

    .radio-group-button {
      margin-bottom: 12px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .checkbox-wrap {
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0.15px;
    color: $color-dark-grey-text;

    .fill-input {
      position: relative;
      width: 100%;
      min-height: 100px;
      margin-bottom: 20px;

      ._error {
        color: red;
      }

      .mat-mdc-form-field-subscript-wrapper {
        overflow: visible;
        height: 0;
      }
      .mdc-text-field--filled {
        padding: 0;
        // background-color: $color-light-grey-40 !important;
      }

      .mat-mdc-form-field-focus-overlay {
        opacity: 0;
      }
      .mat-mdc-form-field-flex {
        height: 52px;
        background-color: $color-light-grey-40 !important;

        .mat-mdc-form-field-label {
          margin-top: 4px;
          margin-left: 2px;
        }

        .mat-mdc-form-field-infix {
          padding: 12px;
        }

        .mat-mdc-input-element {
          border: none;
          border-radius: 4px;
          background-color: transparent;
          padding-top: 16px;
          padding-bottom: 0;
          padding-left: 3px;
          color: $color-txt;
        }

        .tip {
          svg {
            position: relative;
            width: 16px;
            height: 16px;
            top: 2px;
            margin-left: -10px;
            margin-right: 0;
            cursor: default;
            z-index: 9;
          }
        }
      }
    }
  }


}
