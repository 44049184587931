@import "variables.scss";

.academy-employees-accordion {
    .mat-expansion-panel .mat-expansion-panel-body {
        background: white !important;
    }

    .mat-expansion-indicator {
        background: white;
    }

    mat-accordion.accordion__container .mat-expansion-panel-header:hover {
        background: white !important;
    }
}

.academy-accordion__container {
    >:last-child {
        .mat-expanded:last-child {
            border-bottom: 1px solid $color-button-disabled;
            padding-bottom: 12px;
        }
    }    
}

.transparent-backdrop .cdk-overlay-backdrop {
    background: transparent !important; /* убирает затемнение фона */
}
