@import "variables";

.close__wrapper {
  width: 24px;
  height: 24px;
  padding: 5px;
  border-radius: 4px;

  mat-icon {
    color: $color-grey;
  }

  &:hover {
    background-color: $color-light-grey-24;
    cursor: pointer;
  }

  &:active {
    background-color: $color-blue-light;

    mat-icon {
      color: $color-blue;
    }
  }
}

mat-icon.material-icons.info-icon {
  width: 16px;
  height: 16px;
  font-size: 16px;
  color: $color-grey-68;
}
