@import "mixins";
@import "variables";

.emoji-picker {
  position: absolute;
  z-index: 9999;
}

.emoji-mart {
  box-shadow: $box-shadow3;
}

.emoji-mart-search-icon {
   top: 7px;
}

.emoji-mart-emoji, .emoji-mart-emoji span {
  cursor: pointer !important;
}

.emoji-picker-bg-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9;
}

.emoji-mart-category-label {
  color: $color-grey-68;
  font-size: 12px;
}
