/* Переменные-цвета проекта */

// Основные цвета
$color-main-text: #181C3C;
$color-dark-grey-text: #4A586D;
$color-white: #fff;
$color-main-bg: #F1F5F8;


// Основые оттенки синего
$color-blue: #34A3FF;
$color-blue-hover: #2072EB;
$color-blue-pressed: #2360D8;
$color-blue-active: #0593FF;
$color-blue-default: #1985FF;
$color-blue-light: rgba(25, 133, 255, 0.1);
$color-blue-marker: #72CCFd;

// Основные оттенки серого
$color-grey: #7E858E;
$color-grey-100: rgba(126, 133, 142, 1);
$color-grey-60: rgba(126, 133, 142, 0.68);
$color-grey-40: rgba(126, 133, 142, 0.4);
$color-grey-40-no-alpha: #CBCED2;
$color-grey-4: rgba(126, 133, 142, 0.04);
$color-grey-bg: #FFFFFF;

// Основные оттенки светло-серого
$color-light-grey: #D7DFE9;
$color-light-grey-68: rgba(215, 223, 233, 0.68);
$color-light-grey-40: rgba(215, 223, 233, 0.4);
$color-light-grey-100: #d7dfe9;
$color-light-grey-hover: rgba(215, 223, 233, 0.24);
$color-light-grey-bg: linear-gradient(0deg, rgba(107, 117, 129, 0.1), rgba(107, 117, 129, 0.1)), #FFFFFF;
$color-light-grey-bg-light: #E8F3FF;
$color-light-grey-new: #FBFCFD;

// Кнопки
$color-button-hover: rgba(0, 0, 0, 0.04);
$color-button-toggle-off: #fafafa;
$color-button-focus: rgba(0, 0, 0, 0.12);
$color-button-active: rgba(25, 133, 255, 0.12);
$color-button-image-hover: rgba(255, 255, 255, 0.12);
$color-button-image-focus: rgba(255, 255, 255, 0.24);

// Цвета для карточек
$color-card-bg-hover: rgba(26, 133, 255, 0.04);

// Success
$color-green: #2FBF6F;
$color-green-hover: #1DB05F;
$color-success-bg: #F3FFF8;
$color-success-bg-transparent: #F3FFF880;
$color-success-bg3: #DBFBEA;
$color-green-bg: #F3FFF8;
$color-green-bg-tab: rgba(56, 221, 132, 0.1);
$color-green-bg-tag-dark: #CAF9E0;

// Warning
$color-orange: #FFB800;
$color-orange-pressed: #EAA800;
$color-orange-hover: #F2AE00;
$color-warning-bg: #FFF8E6;
$color-warning-bg2:  #FFF8E680;
$color-warning-bg3: #FFF0D9;
$color-green-bg: #FFF8E6;
$color-green-bg3: rgba(219, 251, 234, 1);
$color-orange-bg-tab: rgba(249, 149, 0, 0.1);
$color-yellow-marker: #FFFA0E;

// Error
$color-error: #F03E3E;
$color-error-pressed: #D7042A;
$color-error-hover: #EF0D36;
$color-error-bg: #FFF3F5;
$color-error-bg3: #FFE3E8;
$color-error-soft: #f68b8b;

$color-1c: #FFD08C;
