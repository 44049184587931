/* roboto */
/*@font-face {*/
/*  font-family: 'Roboto';*/
/*  font-style: normal;*/
/*  font-weight: 400;*/
/*  src: url('./Roboto-Regular.eot');*/
/*  src: url('./Roboto-Regular.eot?#iefix') format('embedded-opentype'),*/
/*    url('./Roboto-Regular.woff2') format('woff2'),*/
/*    url('./Roboto-Regular.woff') format('woff'),*/
/*  url('./Roboto-Regular.ttf') format('truetype'),*/
/*  url('./Roboto-Regular.svg#Roboto-Regular') format('svg');*/
/*}*/

/*@font-face {*/
/*  font-family: 'Roboto';*/
/*  font-style: normal;*/
/*  font-weight: 800;*/
/*  src: url('./Roboto-Bold.eot');*/
/*  src: url('./Roboto-Bold.eot?#iefix') format('embedded-opentype'),*/
/*    url('./Roboto-Bold.woff2') format('woff2'),*/
/*    url('./Roboto-Bold.woff') format('woff'),*/
/*  url('./Roboto-Bold.ttf') format('truetype'),*/
/*  url('./Roboto-Bold.svg#Roboto-Bold') format('svg');*/
/*}*/

/*@font-face {*/
/*  font-family: 'Roboto';*/
/*  font-style: normal;*/
/*  font-weight: 500;*/
/*  src: url('./Roboto-Medium.ttf');*/
/*  src: url('./Roboto-Medium.ttf') format('truetype');*/
/*}*/
