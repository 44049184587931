@import 'variables';

.mat-mdc-checkbox {
    display: flex;
    flex-direction: row;
    align-items: center;
    //width: 16px;

    label {
        letter-spacing: normal;
    }

    &._wrap {
        /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
        .mat-checkbox-label,
        .mat-checkbox-layout {
            line-height: 20px;
            white-space: normal;
        }
    }

    .mdc-checkbox {
        padding: 0;
        overflow: hidden; // не совсем правильно, по пусть пока так будет

        &:hover {
            background-color: transparent;

            .mdc-checkbox__native-control:not(:checked)~.mdc-checkbox__background,
            .mdc-checkbox__native-control:not(:indeterminate)~.mdc-checkbox__background {
                border-color: #7e858ead;
            }

            .mdc-checkbox__native-control:checked~.mdc-checkbox__background {
            border-color: $color-blue;
            }
        }

        .mdc-checkbox__ripple {
            display: none!important;
        }
    }

    .mdc-checkbox__background {
        width: 16px;
        height: 16px;
        left: 0;
        top: 0;
    }
}

.mdc-checkbox--disabled.mat-mdc-checkbox-disabled-interactive .mdc-checkbox__background {
    border-color: #7e858e66!important;
}

/* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
.mat-checkbox-layout {
    display: flex;
    align-items: center;
}

.filter-helper {
    width: 100%;

    &._36 {
        align-items: center;
        height: 36px;
        /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
        .mat-checkbox-layout {
            align-items: center;
            height: 36px;
        }
    }

    .mdc-form-field--align-end > label {
        margin-left: 0;
    }
    /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
    /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
    .mat-checkbox-layout,
    .mat-radio-label,
    .mdc-form-field {
        display: flex;
        justify-content: space-between;
        margin-bottom: 8px;
        width: 100%;
        /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
        .mat-checkbox-inner-container {
            margin-right: 0;
        }
    }
}

.checkbox-18px {
    .mdc-checkbox__background {
        margin-right: 11px;
    }

    &._black {
        &.mat-mdc-checkbox-checked .mdc-checkbox__background {
            background-color: $color-dark-grey-text;
        }
    }
}

.mat-mdc-checkbox._column {
    /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
    .mat-internal-form-field {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-right: 14px;
    }

    label {
        padding-left: 0 !important;
    }
    /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
    .mat-checkbox-inner-container {
        margin-right: 0;
        margin-bottom: 8px;
        margin-left: 0;
    }
    /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
    .mat-checkbox-label {
        color: $color-main-text;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
    }
}

.mat-mdc-checkbox.gray-label {
    /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
    .mat-checkbox-label {
        color: $color-dark-grey-text;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.15px;
    }
}

.mat-mdc-checkbox._small {
    /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
    .mat-checkbox-label {
        font-size: 12px;
        line-height: 14px;
    }
    /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
    .mat-checkbox-inner-container {
        margin-right: 6px;
        width: 12px;
        height: 12px;
    }
}

.mat-mdc-checkbox._white-bg {
    /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
    .mat-checkbox-inner-container {
        background: white;
    }
}

.members {
    /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
    .mat-mdc-checkbox .mat-checkbox-ripple {
        opacity: 0;
    }
}

.mat-mdc-checkbox.checkbox.green {
    /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
    /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
    &.mat-checkbox-checked {
        /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
        & .mat-checkbox-background {
            background-color: $color-success;
        }
    }
    /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
    & .mat-checkbox-inner-container {
        border-radius: 4px;
        width: 18px;
        height: 18px;
    }
}

.mat-mdc-checkbox.checkbox.blue {
    /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
    /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
    &.mat-checkbox-checked {
        /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
        & .mat-checkbox-background {
            background-color: $color-blue;
        }
    }
    /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
    & .mat-checkbox-inner-container {
        border-radius: 4px;
        width: 18px;
        height: 18px;
    }
}

.mat-mdc-checkbox.checkbox.red {
    /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
    /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
    &.mat-checkbox-checked {
        /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
        & .mat-checkbox-background {
            background-color: $color-error;
        }
    }
    /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
    & .mat-checkbox-inner-container {
        border-radius: 4px;
        width: 18px;
        height: 18px;
    }
}
